import dashboard from './dashboard';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pages]
};

export default menuItems;
