export const cablesCategory = [
{
    id:1,
    name:"DSTV Subscription",
    value:"dstv",

},
{
    id:2,
    name:"GOTV Subscription",
    value:"gotv",
    
},
{
    id:3,
    name:"STARTIMES Subscription",
    value:"startimes",
    
},
{
    id:4,
    name:"SHOWMAX Subscription",
    value:"showmax",
    
}
]