export const electricityVariations = [
    {
        id: 1,
        name: 'Prepaid',
        value: 'prepaid'
    },
    {
        id: 2,
        name: 'Post paid',
        value: 'postpaid'
    }
];
