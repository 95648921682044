// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const GET_LOGGED_IN_USER_REQUEST = 'GET_LOGGED_IN_USER_REQUEST';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';

export const GET_USER_STAT_BY_DATE_REQUEST = 'GET_USER_STAT_BY_DATE_REQUEST';
export const GET_USER_STAT_BY_DATE_SUCCESS = 'GET_USER_STAT_BY_DATE_SUCCESS';
export const GET_USER_STAT_BY_DATE_FAIL = 'GET_USER_STAT_BY_DATE_FAIL';

export const GET_USER_STAT_REQUEST = 'GET_USER_STAT_REQUEST';
export const GET_USER_STAT_SUCCESS = 'GET_USER_STAT_SUCCESS';
export const GET_USER_STAT_FAIL = 'GET_USER_STAT_FAIL';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const FORGET_PIN_REQUEST = 'FORGET_PIN_REQUEST';
export const FORGET_PIN_SUCCESS = 'FORGET_PIN_SUCCESS';
export const FORGET_PIN_FAIL = 'FORGET_PIN_FAIL';

export const RESET_PIN_REQUEST = 'RESET_PIN_REQUEST';
export const RESET_PIN_SUCCESS = 'RESET_PIN_SUCCESS';
export const RESET_PIN_FAIL = 'RESET_PIN_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const GET_GLO_DATA_PLAN_REQUEST = 'GET_GLO_DATA_PLAN_REQUEST';
export const GET_GLO_DATA_PLAN_SUCCESS = 'GET_GLO_DATA_PLAN_SUCCESS';
export const GET_GLO_DATA_PLAN_FAIL = 'GET_GLO_DATA_PLAN_FAIL';

export const GET_MTN_DATA_PLAN_REQUEST = 'GET_MTN_DATA_PLAN_REQUEST';
export const GET_MTN_DATA_PLAN_SUCCESS = 'GET_MTN_DATA_PLAN_SUCCESS';
export const GET_MTN_DATA_PLAN_FAIL = 'GET_MTN_DATA_PLAN_FAIL';

export const GET_MTN_SME_DATA_PLAN_REQUEST = 'GET_MTN_SME_DATA_PLAN_REQUEST';
export const GET_MTN_SME_DATA_PLAN_SUCCESS = 'GET_MTN_SME_DATA_PLAN_SUCCESS';
export const GET_MTN_SME_DATA_PLAN_FAIL = 'GET_MTN_SME_DATA_PLAN_FAIL';

export const GET_MTN_SME_1_DATA_PLAN_REQUEST = 'GET_MTN_SME_1_DATA_PLAN_REQUEST';
export const GET_MTN_SME_1_DATA_PLAN_SUCCESS = 'GET_MTN_SME_1_DATA_PLAN_SUCCESS';
export const GET_MTN_SME_1_DATA_PLAN_FAIL = 'GET_MTN_SME_1_DATA_PLAN_FAIL';

export const GET_MTN_SME_2_DATA_PLAN_REQUEST = 'GET_MTN_SME_2_DATA_PLAN_REQUEST';
export const GET_MTN_SME_2_DATA_PLAN_SUCCESS = 'GET_MTN_SME_2_DATA_2LAN_SUCCESS';
export const GET_MTN_SME_2_DATA_PLAN_FAIL = 'GET_MTN_SME_2_DATA_PLAN_FAIL';

export const GET_MTN_COUPON_DATA_PLAN_REQUEST = 'GET_MTN_COUPON_DATA_PLAN_REQUEST';
export const GET_MTN_COUPON_DATA_PLAN_SUCCESS = 'GET_MTN_COUPON_DATA_PLAN_SUCCESS';
export const GET_MTN_COUPON_DATA_PLAN_FAIL = 'GET_MTN_COUPON_DATA_PLAN_FAIL';

export const GET_AIRTEL_DATA_PLAN_REQUEST = 'GET_AIRTEL_DATA_PLAN_REQUEST';
export const GET_AIRTEL_DATA_PLAN_SUCCESS = 'GET_AIRTEL_DATA_PLAN_SUCCESS';
export const GET_AIRTEL_DATA_PLAN_FAIL = 'GET_AIRTEL_DATA_PLAN_FAIL';

export const GET_AIRTEL_CG_DATA_PLAN_REQUEST = 'GET_AIRTEL_CG_DATA_PLAN_REQUEST';
export const GET_AIRTEL_CG_DATA_PLAN_SUCCESS = 'GET_AIRTEL_CG_DATA_PLAN_SUCCESS';
export const GET_AIRTEL_CG_DATA_PLAN_FAIL = 'GET_AIRTEL_CG_DATA_PLAN_FAIL';

export const GET_GLO_CG_DATA_PLAN_REQUEST = 'GET_GLO_CG_DATA_PLAN_REQUEST';
export const GET_GLO_CG_DATA_PLAN_SUCCESS = 'GET_GLO_CG_DATA_PLAN_SUCCESS';
export const GET_GLO_CG_DATA_PLAN_FAIL = 'GET_GLO_CG_DATA_PLAN_FAIL';

export const BUY_AIRTIME_REQUEST = 'BUY_AIRTIME_REQUEST';
export const BUY_AIRTIME_SUCCESS = 'BUY_AIRTIME_SUCCESS';
export const BUY_AIRTIME_FAIL = 'BUY_AIRTIME_FAIL';

export const SELL_AIRTIME_REQUEST = 'SELL_AIRTIME_REQUEST';
export const SELL_AIRTIME_SUCCESS = 'SELL_AIRTIME_SUCCESS';
export const SELL_AIRTIME_FAIL = 'SELL_AIRTIME_FAIL';

export const BUY_DATA_REQUEST = 'BUY_DATA_REQUEST';
export const BUY_DATA_SUCCESS = 'BUY_DATA_SUCCESS';
export const BUY_DATA_FAIL = 'BUY_DATA_FAIL';

export const BUY_CG_DATA_REQUEST = 'BUY_CG_DATA_REQUEST';
export const BUY_CG_DATA_SUCCESS = 'BUY_CG_DATA_SUCCESS';
export const BUY_CG_DATA_FAIL = 'BUY_CG_DATA_FAIL';

export const GIFT_DATA_REQUEST = 'GIFT_DATA_REQUEST';
export const GIFT_DATA_SUCCESS = 'GIFT_DATA_SUCCESS';
export const GIFT_DATA_FAIL = 'GIFT_DATA_FAIL';

export const FUND_WALLET_BY_MONNIFY_REQUEST = 'FUND_WALLET_BY_MONNIFY_REQUEST';
export const FUND_WALLET_BY_MONNIFY_SUCCESS = 'FUND_WALLET_BY_MONNIFY_SUCCESS';
export const FUND_WALLET_BY_MONNIFY_FAIL = 'FUND_WALLET_BY_MONNIFY_FAIL';

// Vtu Constants

export const GET_VARIANTS_REQUEST = 'GET_VARIANTS_REQUEST';
export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const GET_VARIANTS_FAIL = 'GET_VARIANTS_FAIL';

export const VERIFY_DETAILS_REQUEST = 'VERIFY_DETAILS_REQUEST';
export const VERIFY_DETAILS_SUCCESS = 'VERIFY_DETAILS_SUCCESS';
export const VERIFY_DETAILS_FAIL = 'VERIFY_DETAILS_FAIL';

export const VERIFY_METER_REQUEST = 'VERIFY_METER_REQUEST';
export const VERIFY_METER_SUCCESS = 'VERIFY_METER_SUCCESS';
export const VERIFY_METER_FAIL = 'VERIFY_METER_FAIL';

export const GET_ELECTRICITY_PROVIDERS_REQUEST = 'GET_ELECTRICITY_PROVIDERS_REQUEST';
export const GET_ELECTRICITY_PROVIDERS_SUCCESS = 'GET_ELECTRICITY_PROVIDERS_SUCCESS';
export const GET_ELECTRICITY_PROVIDERS_FAIL = 'GET_ELECTRICITY_PROVIDERS_FAIL';

export const BUY_TV_CABLES_REQUEST = 'BUY_TV_CABLES_REQUEST';
export const BUY_TV_CABLES_SUCCESS = 'BUY_TV_CABLES_SUCCESS';
export const BUY_TV_CABLES_FAIL = 'BUY_TV_CABLES_FAIL';

export const BUY_ELECTRICITY_REQUEST = 'BUY_ELECTRICITY_REQUEST';
export const BUY_ELECTRICITY_SUCCESS = 'BUY_ELECTRICITY_SUCCESS';
export const BUY_ELECTRICITY_FAIL = 'BUY_ELECTRICITY_FAIL';

export const BUY_EXAM_PIN_REQUEST = 'BUY_EXAM_PIN_REQUEST';
export const BUY_EXAM_PIN_SUCCESS = 'BUY_EXAM_PIN_SUCCESS';
export const BUY_EXAM_PIN_FAIL = 'BUY_EXAM_PIN_FAIL';

export const GET_TRANSACTION_HISTORY_REQUEST = 'GET_TRANSACTION_HISTORY_REQUEST';
export const GET_TRANSACTION_HISTORY_SUCCESS = 'GET_TRANSACTION_HISTORY_SUCCESS';
export const GET_TRANSACTION_HISTORY_FAIL = 'GET_TRANSACTION_HISTORY_FAIL';

export const GET_SELL_AIRTIME_DETAILS_REQUEST = 'GET_SELL_AIRTIME_DETAILS_REQUEST';
export const GET_SELL_AIRTIME_DETAILS_SUCCESS = 'GET_SELL_AIRTIME_DETAILS_SUCCESS';
export const GET_SELL_AIRTIME_DETAILS_FAIL = 'GET_SELL_AIRTIME_DETAILS_FAIL';

export const GET_BTC_DETAILS_REQUEST = 'GET_BTC_DETAILS_REQUEST';
export const GET_BTC_DETAILS_SUCCESS = 'GET_BTC_DETAILS_SUCCESS';
export const GET_BTC_DETAILS_FAIL = 'GET_BTC_DETAILS_FAIL';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';

export const GENERATE_mONNIFY_ACCOUNT_REQUEST = 'GENERATE_mONNIFY_ACCOUNT_REQUEST';
export const GENERATE_mONNIFY_ACCOUNT_SUCCESS = 'GENERATE_mONNIFY_ACCOUNT_SUCCESS';
export const GENERATE_mONNIFY_ACCOUNT_FAIL = 'GENERATE_mONNIFY_ACCOUNT_FAIL';
