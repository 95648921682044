export const examVariations = [
    {
        id: 1,
        name: 'Waec Registration',
        value: 'waec-registration'
    },
    {
        id: 2,
        name: 'Waec Result Checker',
        value: 'waec'
    }
];
